import { useRemarkSync } from 'react-remark';

export function MarkdownText({ text }: { text: string }) {
  const reactContent = useRemarkSync(text);

  return (
    /**
     * markdown-text class lets us target CSS of any elements generated
     * whitespace-pre-line ensures line breaks are correct
     */
    <div className="markdown-text whitespace-pre-line">{reactContent}</div>
  );
}
