import { useQuery, DocumentNode } from '@apollo/client';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export function useDataWithFallback<data>(
  getData: () => Promise<data>,
  fallback?: data
) {
  const [value, setValue] = useState(fallback);

  useEffect(() => {
    setValue(fallback);
  }, [fallback]);

  useEffect(() => {
    getData().then((newValue) => {
      setValue(newValue);
    });
  }, [getData]);

  return value;
}

/**
 * Refetch graphql data on the client side that was prefetched in SSG to prevent caching issues
 */
export function useQueryWithFallback<data>(
  query: DocumentNode,
  fallback?: data
) {
  const router = useRouter();
  const [value, setValue] = useState(fallback);
  const { data } = useQuery<data>(query);

  useEffect(() => {
    setValue(fallback);
  }, [fallback]);

  // When we are previewing, we don't want to refetch the data
  if (router.isPreview) {
    return value;
  }

  return data ?? value;
}
