import Layout from "@components/Layout";
import { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import Link from 'next/link';
import { Controller } from 'swiper';

import { GetStaticPropsContext } from 'next';
import { HeaderText, SmallPageHeader } from '@components/Text';
import { gql } from '@apollo/client';
import { GetHomePageQuery, GetHomePageQueryVariables } from 'generated/graphql';
import { ContentPadding, FullBleedPadding } from '@components/ContentPadding';
import ObscuraPhotographersTable from '@components/ObscuraPhotographersTable';
import { Media } from '@components/Media';
import { getClient } from 'db/contentAPI';
import { routes } from '@utils/routes';
import { Carousel } from '@components/Carousel';
import { SwiperSlide } from 'swiper/react';
import { COMMISSIONS, sortByCommission } from 'logic/commissions';
import { ButtonLink } from '@components/Link';
import { useRouter } from 'next/router';
import { MarkdownText } from '@components/MarkdownText';
import { useQueryWithFallback } from 'hooks/useDataWithFallback';
import { HOMEPAGE_ID } from 'logic/pages';

export default function Home({
  data,
}: {
  data: GetHomePageQuery;
}): ReactElement {
  const router = useRouter();
  const [collectionSwiper, setCollectionSwiper] = useState(null);
  const homepageResponse = useQueryWithFallback(queryHomepage, data);
  const {
    headline: cta1,
    cta2,
    cta2SubCopy,
    statsHeader,
    cta3,
    cta3SubCopy,
    isAnnouncementVisible,
    annonuncementCtaButtonText,
    announcementCta,
    announcementCtaLink,
    announcementCtaSubCopy,
    statsCollection,
  } = homepageResponse.homepage ?? {};
  const featured =
    homepageResponse?.homepage?.featuredCollectionsCollection.items ?? [];
  const collections = homepageResponse?.collectionCollection.items ?? [];
  const commissionTiers = homepageResponse.commisionCollection?.items ?? [];
  const styles = {
    statsSectionTitles: 'font-semibold mb-4 sm:text-4xl',
    carouselWrapper: 'flex flex-row overflow-x-scroll mb-20 relative',
    carouselImage: 'flex-1 self-end relative h-full w-auto',
  };

  return (
    <Layout>
      {isAnnouncementVisible && (
        <FullBleedPadding className="flex flex-row justify-between items-center bg-black text-white py-4 mb-4">
          <div>
            <h4 className="mb-2 text-lg font-semibold text-white">
              {announcementCta}
            </h4>
            <p className="text-sm mb-2">{announcementCtaSubCopy}</p>
          </div>
          {announcementCtaLink && (
            <ButtonLink
              href={announcementCtaLink}
              className="font-semibold"
              backgroundColor="bg-white"
              color="text-black"
            >
              {annonuncementCtaButtonText}
            </ButtonLink>
          )}
        </FullBleedPadding>
      )}
      <div className="bg-white overflow-hidden">
        <FullBleedPadding className="mx-auto text-center justify-around flex flex-wrap">
          <div className="w-full">
            <SmallPageHeader>
              <MarkdownText text={cta1} />
            </SmallPageHeader>
          </div>
        </FullBleedPadding>
        <div className={styles.carouselWrapper}>
          <Carousel
            maxHeight={800}
            modules={[Controller]}
            controller={{ control: collectionSwiper }}
            onSwiper={setCollectionSwiper}
            height={'80vh'}
            itemCount={featured.length}
            // slideToClickedSlide
          >
            {featured.map((collection) => (
              <SwiperSlide key={collection.slug}>
                {(props) => (
                  <a
                    className="mr-5 w-full h-full cursor-pointer relative flex flex-1"
                    onClick={() => {
                      if (props.isActive) {
                        router.push(routes.collectionDetails(collection.slug));
                      } else {
                        const nextIndex = props.isNext
                          ? collectionSwiper.activeIndex + 1
                          : collectionSwiper.activeIndex - 1;
                        collectionSwiper?.slideTo?.(nextIndex);
                      }
                    }}
                  >
                    <div className="absolute top-0 left-0 w-full h-full text-3xl font-semibold transition-opacity text-white opacity-0 hover:opacity-100 z-10 flex flex-col justify-center items-center text-center pb-6">
                      <div className="z-10">
                        <p>{collection.projectName}</p>
                        <p>{collection.name}</p>
                      </div>
                      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-40" />
                    </div>
                    <div className={styles.carouselImage}>
                      <Media
                        src={collection.image.url}
                        alt={collection.name}
                        layout="fill"
                        className="object-cover"
                      />
                    </div>
                  </a>
                )}
              </SwiperSlide>
            ))}
          </Carousel>
        </div>

        <ContentPadding className="bg-black text-white py-20 sm:py-40">
          <HeaderText as="h1" className="mb-5 text-white">
            <MarkdownText text={cta2} />
          </HeaderText>
          <p className="w-6/12 text-lg">
            <MarkdownText text={cta2SubCopy} />
          </p>
        </ContentPadding>

        <ContentPadding>
          <div className="my-24">
            <h4 className="font-semibold text-5xl mb-8">
              <MarkdownText text={statsHeader} />
            </h4>
            <div className="flex flex-row justify-between">
              {statsCollection.items.map((stat, index) => (
                <div key={index}>
                  <p className={styles.statsSectionTitles}>
                    <MarkdownText text={stat.title} />
                  </p>
                  <MarkdownText text={stat.description} />
                </div>
              ))}
            </div>
          </div>
        </ContentPadding>

        <ContentPadding className="bg-black text-white">
          <div className="flex flex-col sm:flex-row my-24 sm:items-center">
            <HeaderText className="w-1/2 text-white py-10 sm:py-20">
              {cta3}
            </HeaderText>
            <div className="w-1/2">
              <p className="text-lg">{cta3SubCopy}</p>
              <ButtonLink
                href={routes.howItWorks}
                backgroundColor="bg-white"
                color="text-black"
                className="mb-5 sm:mb-0 mt-5"
              >
                Learn More
              </ButtonLink>
            </div>
          </div>
        </ContentPadding>
        {/* todo: reduce space above */}
        <ContentPadding>
          <HeaderText as="h1" className="mb-5">
            Obscura Commission Tiers
          </HeaderText>
          <p className="w-6/12 my-5 font-semibold">
            Explore our commission tiers
          </p>
        </ContentPadding>
        <div className={styles.carouselWrapper}>
          <Carousel
            className="mt-6"
            height={'50vh'}
            maxHeight={500}
            itemCount={4}
          >
            {[...commissionTiers]
              .sort((a, b) => sortByCommission(a, b))
              .map((commissionTier, index) => (
                <SwiperSlide key={index}>
                  <Link
                    href={routes.commissionTier(commissionTier.slug)}
                    passHref
                  >
                    <a className="mr-5 w-full h-full cursor-pointer relative flex flex-1">
                      <div
                        style={{
                          backgroundImage: `url(${
                            collections.find(
                              (c) =>
                                c.season.commission.slug ===
                                  commissionTier.slug && !!c.image?.url
                            )?.image?.url
                          })`,
                        }}
                        className="bg-cover bg-center absolute top-0 left-0 w-full h-full text-3xl font-semibold transition-opacity bg-white opacity-0 hover:opacity-100 z-10 flex flex-col justify-center items-center text-center pb-6"
                      >
                        <p className="font-semibold text-3xl text-white no-i18n">
                          View Commission Tier
                        </p>
                        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-40" />
                      </div>
                      {/* <div
                        className={clsx(
                          styles.carouselImage,
                          'absolute top-0 left-0 z-10 w-full h-full'
                        )}
                      >
                        <Media
                          src={
                            collections.find(
                              (c) =>
                                c.season.commission.slug === commissionTier.slug
                            ).image.url
                          }
                          alt={commissionTier.name}
                          layout="fill"
                          className="object-cover"
                        />
                      </div> */}
                      <div className={styles.carouselImage}>
                        <Media
                          src={
                            {
                              [COMMISSIONS.community]: '/tiers/community.png',
                              [COMMISSIONS.curated]: '/tiers/curated.png',
                              [COMMISSIONS.magnum]: '/tiers/magnum.png',
                              [COMMISSIONS.foundry]: '/tiers/foundry.png',
                            }[commissionTier.slug]
                          }
                          alt={commissionTier.name}
                          layout="fill"
                          className="object-cover"
                        />
                      </div>
                    </a>
                  </Link>
                </SwiperSlide>
              ))}
          </Carousel>
        </div>

        <FullBleedPadding>
          <div className="text-left sm:pt-4 justify-around flex flex-wrap">
            <div className="text-left w-full">
              <HeaderText as="h3" className="mb-2">
                Recent Obscura Drops
              </HeaderText>
              <div className="w-4/12 font-semibold text-lg">
                Explore Obscura&apos;s latest Commissioned Artists
              </div>
            </div>
          </div>
        </FullBleedPadding>
        <ObscuraPhotographersTable photographers={collections} />
      </div>
    </Layout>
  );
}

const queryHomepage = gql`
  query GetHomePage($preview: Boolean) {
    homepage(id: "${HOMEPAGE_ID}", preview: $preview) {
      headline
      cta2
      cta2SubCopy
      statsHeader
      statsCollection {
        items {
          title
          description
        }
      }
      cta3
      cta3SubCopy
      isAnnouncementVisible
      announcementCta
      announcementCtaLink
      announcementCtaSubCopy
      annonuncementCtaButtonText
      featuredCollectionsCollection {
        items {
          ...homepageCollections
        }
      }
    }
    commisionCollection {
      items {
        slug
        name
        description
      }
    }
    collectionCollection(order: droptime_DESC) {
      items {
        ...homepageCollections
      }
    }
  }
  fragment homepageCollections on Collection {
    slug
    name
    projectName
    image {
      url
    }
    type
    season {
      commission {
        slug
        name
      }
    }
  }
`;

export async function getStaticProps({
  preview,
  previewData,
}: GetStaticPropsContext) {
  const { data, error } = await getClient(
    (previewData as { api: string })?.api
  ).query<GetHomePageQuery, GetHomePageQueryVariables>({
    query: queryHomepage,
    variables: {
      preview: !!preview,
    },
  });

  if (error) {
    console.error(
      `Failed to fetch homepage, received ${JSON.stringify(error)}`
    );
  }

  return {
    props: {
      data,
      fallback: true,
      preview: preview ?? false,
      revalidate: 120,
    },
  };
}