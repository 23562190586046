import { ReactElement, useMemo } from 'react';
import Link from 'next/link';
import { FullBleedPadding } from './ContentPadding';
import { routes } from '@utils/routes';

export default function ObscuraPhotographersTable({
  photographers,
}: {
  photographers: Array<{
    name?: string;
    projectName?: string;
    slug?: string;
    season?: {
      commission?: {
        slug?: string;
        name?: string;
      };
    };
  }>;
}): ReactElement {
  const collections = useMemo(() => {
    return photographers.length ? photographers.slice(0, 5) : [];
  }, [photographers]);

  return (
    <div className="grid mt-4 w-full">
      {collections.map((item) => (
        <div key={item.slug} className="py-6 border-black border-t-2">
          <FullBleedPadding className="grid grid-cols-4">
            <div>{item.name}</div>
            <div>{item.projectName}</div>
            <div>{item.season.commission?.name ?? 'Commission Tier'}</div>
            <div className="text-right">
              <Link href={routes.collectionDetails(item.slug)}>
                <a className="hover:text-gray-600 underline">View Collection</a>
              </Link>
            </div>
          </FullBleedPadding>
        </div>
      ))}
      <FullBleedPadding className="bg-black w-full py-5">
        <Link href="/collections" passHref>
          <a className="text-white text-lg font-semibold cursor-pointer">
            View All Collections
          </a>
        </Link>
      </FullBleedPadding>
    </div>
  );
}
