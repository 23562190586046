import { Scrollbar, Autoplay } from 'swiper';
import { Swiper, SwiperProps } from 'swiper/react';

interface CarouselProps extends Omit<SwiperProps, 'height'> {
  height: string;
  maxHeight?: number;
  itemCount: number;
}

export function Carousel({
  height,
  maxHeight,
  itemCount,
  children,
  ...swiperProps
}: CarouselProps) {
  return (
    <Swiper
      style={{ maxHeight: maxHeight ?? height, minHeight: height }}
      slidesPerView={1.5}
      centeredSlides
      scrollbar
      loop
      loopedSlides={itemCount}
      spaceBetween={5}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      autoplay={{
        delay: 4000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      }}
      {...swiperProps}
      modules={[Autoplay, Scrollbar, ...(swiperProps.modules ?? [])]}
    >
      {children}
    </Swiper>
  );
}
