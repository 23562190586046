import { COMMISSIONS } from 'logic/commissions';

export const routes = {
  howItWorks: '/patron-pass',
  collectionDetails: (slug: string) => `/collections/${slug}`,
  communityProjectDetails: (slug: string) => `/community-projects/${slug}`,
  collectionGallery: (slug: string, tokenId: number) =>
    `/collections/${slug}/gallery?tokenId=${tokenId}`,
  commissionTier: (slug) => {
    if (!COMMISSIONS[slug]) {
      console.warn('Unknown commission tier slug', slug);
    }
    return `/commissions/${slug}`;
  },
  passAvailability: (slug: COMMISSIONS | '') => {
    return `/pass-availability?commission=${slug}`;
  },
};
