export enum COMMISSIONS {
  'magnum' = 'magnum',
  'curated' = 'curated',
  'foundry' = 'foundry',
  'community' = 'community',
}


const commissionsSortOrder = [
  COMMISSIONS.curated,
  COMMISSIONS.magnum,
  COMMISSIONS.foundry,
  COMMISSIONS.community,
];

export const sortByCommission = (
  a: { slug?: string },
  b: { slug?: string }
) => {
  return (
    commissionsSortOrder.indexOf(a.slug as COMMISSIONS) -
    commissionsSortOrder.indexOf(b.slug as COMMISSIONS)
  );
};
