import React from 'react';
import styled from 'styled-components';

interface HeaderTextProps {
  children: React.ReactNode;
  className?: string;
  as: 'h1' | 'h2' | 'h3';
}

const HeaderTextStyle = `
  text-align: left;
  font-weight: bold;
`;

const PageHeaderComp = styled.div`
  ${HeaderTextStyle};
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 30px;
  margin-top: 30px;
`;

const HeaderTextComp = styled.div`
  ${HeaderTextStyle}
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 50px;
`;

const SmallPageHeaderComp = styled.div`
  ${HeaderTextStyle}
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 50px;
`;

const Paragraph = styled.p`
  font-size: 20px;

`;

export const HeaderText = ({
  children,
  className,
}: HeaderTextProps) => {
  return (
    <HeaderTextComp className={className}>
      {children}
    </HeaderTextComp>
  );
};

HeaderText.defaultProps = {
  as: 'h3',
};

export const pageHeaderStyle = 'mt-10 sm:mt-22 mb-10 sm:mb-16 text-2xl';

export const PageHeader = (props: Omit<HeaderTextProps, 'as'>) => {
  return (
    <PageHeaderComp
      as="h1"
    >
      {props.children}
    </PageHeaderComp>
  );
};

// For page headers with lots of text like homepage or how it works
export const SmallPageHeader = (props: Omit<HeaderTextProps, 'as'>) => {
  return (
    <SmallPageHeaderComp
      as="h2"
    >
      {props.children}
    </SmallPageHeaderComp>
  );
};

export const H3 = (props: Omit<HeaderTextProps, 'as'>) => {
  return (
    <h3
      className={`font-semibold text-4xl my-6 ${props.className}`}
    >
      {props.children}
    </h3>
  );
};

export const P = (props: Omit<HeaderTextProps, 'as'>) => {
  return (
    <Paragraph>
      {props.children}
    </Paragraph>
  )
}