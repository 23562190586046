import Image, { ImageProps } from 'next/image';

interface MediaProps extends ImageProps {
  type?: string;
  src: string;
}

/**
 * Encapuslates conditionally displaying a video or image depending on the content passed in
 * Handles other common tasks needed for displaying images from Arweave and defaulting a blur image for loading states
 */
export function Media({ type, src, className, ...props }: MediaProps) {
  const isVideo =
    type === 'video' ||
    src?.includes('videos.ctfassets.net') ||
    src?.includes('mp4');

    if(!src) return null;

  return isVideo ? (
    <video
      className={className}
      src={src?.replace('.jpg', '.mp4')}
      loop
      muted
      autoPlay={true}
      playsInline
      controlsList="nodownload"
    />
  ) : (
    <Image
      className={className}
      alt=""
      placeholder="blur"
      // default blur image to a random image to provide a placeholder while large images load in
      blurDataURL="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gIoSUNDX1BST0ZJTEUAAQEAAAIYAAAAAAQwAABtbnRyUkdCIFhZWiAAAAAAAAAAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAAHRyWFlaAAABZAAAABRnWFlaAAABeAAAABRiWFlaAAABjAAAABRyVFJDAAABoAAAAChnVFJDAAABoAAAAChiVFJDAAABoAAAACh3dHB0AAAByAAAABRjcHJ0AAAB3AAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAFgAAAAcAHMAUgBHAEIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFhZWiAAAAAAAABvogAAOPUAAAOQWFlaIAAAAAAAAGKZAAC3hQAAGNpYWVogAAAAAAAAJKAAAA+EAAC2z3BhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABYWVogAAAAAAAA9tYAAQAAAADTLW1sdWMAAAAAAAAAAQAAAAxlblVTAAAAIAAAABwARwBvAG8AZwBsAGUAIABJAG4AYwAuACAAMgAwADEANv/bAEMAFA4PEg8NFBIQEhcVFBgeMiEeHBwePSwuJDJJQExLR0BGRVBac2JQVW1WRUZkiGVtd3uBgoFOYI2XjH2Wc36BfP/bAEMBFRcXHhoeOyEhO3xTRlN8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fP/AABEIAKYApgMBIgACEQEDEQH/xAAYAAEBAQEBAAAAAAAAAAAAAAAAAQIDBP/EABcQAQEBAQAAAAAAAAAAAAAAAAARARL/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAwDAQACEQMRAD8A9gDSAAIKAgAAAiCgIKAgqCgqAAAAA2KAiNICCgIAIgoCCgIKAgqCgAIAAADrBpEERpARGkVEFQAAAUBBQERUFQVAQEAAB6UaRERFAZFQEAUAAFRRRFEERUBE1dZ0EDUFBAHsRUGRBAEEAQAFQFVUAUQATRNFTWdXU0E1DUQBAHtSpSiFTdKzugu6lTdSqLSs0oNUrNKDdKzVoKVKVFE0qboJuppus7oG6zTdSirRkB7KlZpRGqzupUoLupWalBqlYpQbpWKtBulYq0G6lZpRWqzupU3UF3Wd03Wd0F3WabrNFaozQHpqVilEaqVmpQaqVmpQapWKUG6VilFdKtc6UHSlc6UG6lZpUF3U3UqUF3UqVKKtGaA60rn0VUbqVilFaqVmpUG6lYpQjdKxSix0pXOrUI6dFc6UHSpWaUGqlZpQaqVKVRaM0BalZpQaqVmlBqpWaVFWlZpQapWKVBurWKUG6VmlBulZpQapUooogIogCAKqAIIACAIqAICgAoAoCigCKAoAA//Z"
      quality={100}
      height={props.layout === 'fill' ? undefined : '100%'}
      width={props.layout === 'fill' ? undefined : '100%'}
      {...props}
      /**
       * Switch images to use Meson Network's dCDN gateway for Arweave
       */
      src={src?.replace(
        'https://arweave.net',
        'https://coldcdn.com/api/cdn/arweave/'
      )}
    />
  );
}
